<template>
  <!--课程目录-->
  <div class="wxq_catalogue">
    <div class="wxq_section" v-for="(item,index) in courseList" :key="index">
      <p class="wxq_section_title" :class="{'wxq_section_hover' :item.children.length == 0}"
         :title="item.chapterName" @click="getCoursewareContent(item)">
        {{ item.chapterTitle }}：{{ item.chapterName }}
      </p>
      <!--      <template v-if="item.children[0].chapterName != ''">-->
      <p class="wxq_unit" v-for="(i,inx) in item.children" :key="inx" :title="i.chapterName"
         @click="getCoursewareContent(i)">
        <img src="@/assets/img/studentImg/dibbleDetail/play-icon.png" alt="">
        {{ i.chapterTitle }}：{{ i.chapterName }}
      </p>
      <!--      </template>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "courseCatalogue",
  props: ['section'],
  data() {
    return {
      courseList: [],
    }
  },
  watch: {
    section: {
      handler(val) {
        if (val) {
          this.courseList = val
          if (val[0].chapterName == '') {
            this.$EventBus.$emit('sendBus', val[0].children[0])
          } else {
            this.$EventBus.$emit('sendBus', val[0])
          }
        }
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    getCoursewareContent(val) {
      if (val.filePath) {
        this.$EventBus.$emit('sendBus', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wxq_catalogue {
  height: calc(100% - 57px);
  overflow-y: auto;
  .wxq_section {
    padding: 15px 10px 0;
    .wxq_section_title {
      font-size: 14px;
      font-weight: bold;
      padding: 8px 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .wxq_unit {
      height: 32px;
      line-height: 32px;
      padding: 0 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      img {
        width: 20px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
    .wxq_unit:hover, .wxq_section_hover:hover {
      background: #D9EBFF;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}
</style>
