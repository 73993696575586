<template>
  <!--视频组件-->
  <div class="wxq_video">
    <div class="wxq_live_courseware" v-if="fileGroup == '1'">
      <el-carousel trigger="click" :autoplay="false">
        <el-carousel-item v-for="(item, index) in images" :key="index">
          <el-image :src="item"> </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div
      class="wxq_live_video"
      id="id_test_video"
      v-show="fileGroup == '2'"
    ></div>
    <div class="wxq_live_video" v-show="fileGroup == ''">
      <img :src="coverUrl" />
    </div>
  </div>
</template>

<script>
import coursewareAPI from "@/apis/backStageEndAPI/coursewareManagementAPI";
export default {
  name: "liveVideo",
  props: {
    coverUrl: {
      type: String,
      // defalut: '',
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      fileGroup: "",
      player: "",
      videoInfo: {},
      images: [],
      coverURL: "",
    };
  },
  mounted() {
    this.$EventBus.$on("coverURL", (val) => {
      if (val) {
        this.coverURL = val;
      }
    });
    this.$EventBus.$on("sendBus", (data) => {
      //课程目录传值决定显示视频还是课件
      this.fileGroup = data.fileGroup;
      if (document.getElementById("id_test_video"))
        document.getElementById("id_test_video").innerHTML = "";
      if (data.fileGroup == 1) {
        this.getFileDetail(data.fileId);
      } else {
        this.videoPlayer(data.filePath, this.coverURL);
      }
    });
    this.$EventBus.$on("getPlayerDuration", () => {
      //暂停获取视频秒数
      if (this.fileGroup != 1) {
        this.playerOn();
      }
    });
    this.$EventBus.$on("getPlayerPlay", () => {
      //添加笔记成功后播放视频
      this.player.play();
    });
    // this.videoPlayer()
  },
  methods: {
    videoPlayer(video, coverURL) {
      // let _this = this
      //
      this.player = new TcPlayer("id_test_video", {
        webrtc: video ? video : "",
        flv: video ? video : "",
        rtmp: video ? video : "",
        m3u8: video ? video : "",
        mp4: video ? video : "",
        autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        poster: coverURL ? coverURL : "",
        width: "100%", //视频的显示宽度，请尽量使用视频分辨率宽度
        height: "100%", //视频的显示高度，请尽量使用视频分辨率高度
        wording: {
          2032: "请求视频失败，请检查网络",
          2048: "请求m3u8文件失败，可能是网络错误或者跨域问题",
        },
        listener(msg) {
          if (msg.type == "error") {
          }
        },
      });
    },
    playerOn() {
      this.player.pause();
      this.$EventBus.$emit("sendCurrentTime", this.player.currentTime());
    },
    getFileDetail(fileId) {
      coursewareAPI.fileDetail(fileId).then((res) => {
        this.images = res.docImages.split(",");
      });
    },
  },
  destroyed() {
    this.player.destroy();
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .wxq_live_video {
    .vcp-error-tips {
      position: absolute;
      z-index: 1001;
      width: 100%;
      height: 4.5em;
      left: 0;
      top: 102% !important;
      color: #ff4500;
      margin-top: -5.25em;
      text-align: center;
    }
  }
}
.wxq_live_courseware {
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
  .wxq_iframe {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-carousel {
    height: 100%;
    .el-carousel__container {
      height: 100%;
    }
  }
  .el-image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: auto;
    ::v-deep .el-image__inner {
      height: auto;
    }
  }
  ::v-deep .el-carousel__indicators {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
.wxq_video {
  width: 100%;
  height: 100%;
  .wxq_live_video {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
