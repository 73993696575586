<template>
  <!--我的笔记-->
  <div class="wxq_notes">
    <div class="wxq_notes_detail" v-for="(item,index) in notesList" :key="index">
      <p class="wxq_duration">
        <span><img src="@/assets/img/studentImg/dibbleDetail/duration-icon.png"
                   alt="">{{ formatTime(parseInt(item.noteSec)) }}</span>
        <img src="@/assets/img/studentImg/dibbleDetail/delete-icon.png" alt="" @click="deleteNote(item.noteId)">
      </p>
      <p class="wxq_notes_content">{{ item.noteContent }}</p>
      <p class="wxq_notes_time">{{ item.createTime }}</p>
    </div>
    <!-- 审核不可添加笔记 -->
    <div class="wxq_btn" v-if="review != 'courseReview'">
      <el-popover
        ref="noteRef"
        placement="top"
        width="300"
        trigger="click">
        <div>
          <el-input type="textarea" v-model="addNoteParam.noteContent"></el-input>
          <div class="wxq_score">
            <el-button class="btn btn_blue" @click="getAddNote">添加</el-button>
          </div>
        </div>
        <el-button slot="reference" class="btn btn_blue" @click="add">添加笔记</el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>
import API from "@/apis/studentsEndAPI/liveMenuDetail";
import CommonUtils from "@/utils";
import {formatTime} from "@assets/js/format";
export default {
  name: "myNotes",
  props: ['courseId','review'],
  data() {
    return {
      formatTime,//转换视频秒数显示格式
      userId: '',
      formData: {
        courseId: '',
        keyword: '',
        userId: '',
        page: 1,
        pageSize: 100
      },
      addNoteParam: {
        courseId: '',
        noteContent: '',
        noteSec: 156,
      },
      notesList: []
    }
  },
  watch: {
    courseId: {
      handler(val) {
        if (val) {
          this.getNotesList(val)
          this.addNoteParam.courseId = val
        }
      }
    }
  },
  mounted() {
    if(sessionStorage.getItem('profile')) {
      this.userId = this.formData.userId = JSON.parse(sessionStorage.getItem('profile')).userId
    }
    if (this.courseId) {
      this.getNotesList(this.courseId)
    }
    this.$EventBus.$on('sendCurrentTime', data => { //获取视频秒数
      this.addNoteParam.noteSec = data
    })
  },
  methods: {
    getNotesList(courseId) {//获取笔记列表
      this.formData.courseId = courseId
      if(sessionStorage.getItem('educationToken')) {
        API.getNoteList(this.userId, CommonUtils.parseToParams(this.formData)).then(res => {
          this.notesList = res.dataList
        })
      }
    },
    add() { //点击添加暂停获取视频秒数
      this.$EventBus.$emit('getPlayerDuration')
    },
    getAddNote() {//添加笔记
      this.addNoteParam.courseId = this.courseId
      if (!this.addNoteParam.noteContent) {
        this.$message.warning('请输入笔记内容')
      } else {
        API.getAddNote(this.userId, this.addNoteParam).then(res => {
          if (res && !res.status) {
            this.$refs.noteRef.doClose()
            this.addNoteParam.noteContent = ''
            this.getNotesList(this.courseId)
            this.$EventBus.$emit('getPlayerPlay')
          }
        })
      }
    },
    deleteNote(noteId) {//删除笔记
      API.getDeleteNote(this.userId, noteId).then(res => {
        this.getNotesList(this.courseId)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wxq_notes {
	overflow-y: auto;
	height: 680px;
	.wxq_notes_detail {
		padding: 15px;
		.wxq_duration {
			display: flex;
			align-items: center;
			justify-content: space-between;
			span {
				color: #0a1629;
				img {
					margin-right: 12px;
					width: 18px;
					height: 18px;
					vertical-align: middle;
				}
			}
			img {
				width: 12px;
				height: 12px;
				cursor: pointer;
			}
		}
		.wxq_notes_content {
			padding: 12px 0;
			line-height: 20px;
		}
		.wxq_notes_time {
			color: #91929e;
			text-align: right;
		}
	}
	.wxq_btn {
		position: absolute;
		bottom: 0;
		padding: 12px;
		width: 100%;
		.btn {
			width: 100%;
		}
	}
}
.wxq_score {
	padding-top: 10px;
	text-align: right;
	.btn {
		padding: 0 16px;
		height: 30px;
	}
}
</style>
